import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LISTINGS_UPLOAD_BLUEPRINT } from './blueprint';

export const useBlueprint = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('blueprint');

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const blueprint = useMemo(() => {
    const toTranslate = LISTINGS_UPLOAD_BLUEPRINT;
    toTranslate.fields = toTranslate.fields.map((field) => ({
      ...field,
      label: t(`labels.${field.key}`, { defaultValue: field.key }),
      description: t(`descriptions.${field.key}`, { defaultValue: '' }),
    }));

    return toTranslate;
  }, [locale]);

  return blueprint;
};

import { useAdminListings } from '@/context/admin-listings';
import * as listingsSchemaWithBackendValidation from '@/services/listingsSchemaWithBackendValidation';
import * as listingsUploadClient from '@/services/listingsUpload';
import type { TPrimitive, TRecordDataWithLinks } from '@flatfile/hooks';
import { type FlatfileRecord, recordHook } from '@flatfile/plugin-record-hook';
import { Sheet, Space, useListener } from '@flatfile/react';
import type { LotSchema } from '@reibus/ingest-listings-api-client-axios';
import type { z } from 'zod';
import { COMPUTED_FIELDS, TRANSFORM_FIELDS } from './blueprint';
import { useBlueprint } from './useBlueprint';

type CellData = {
  value: unknown;
  messages: string[];
  valid: boolean;
  updatedAt: string;
};

type SheetData = {
  sheetId: string;
  workbookId: string;
  records: {
    id: string;
    values: Record<string, CellData>[];
  }[];
};

const SPACE_CONFIG = {
  metadata: {
    sidebarConfig: {
      showSidebar: true,
    },
    theme: {
      root: {
        primaryColor: '#2D6CCB',
      },
    },
  },
};

export const FlatfileSheet = () => {
  const { companyId } = useAdminListings();
  const blueprint = useBlueprint();

  const validateRecord = async (
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => {
    try {
      const lot = await listingsSchemaWithBackendValidation.safeParseAsync(
        companyId,
        record.obj,
      );

      if (!lot.success) {
        const errors = lot.error.issues
          .filter(
            (issue) =>
              issue.code !== 'invalid_type' || issue.received !== 'null',
          )
          .map((issue) => {
            return {
              field: issue.path.join('.'),
              message: issue.message,
            };
          });

        errors.forEach((error) => {
          record.addError(error.field, error.message);
        });
      }

      return record;
    } catch (error) {
      console.error('error', error);
      return record;
    }
  };

  useListener((listener) => {
    listener.use(
      recordHook(blueprint.slug, async (record) => {
        Object.entries(COMPUTED_FIELDS).forEach(([fieldName, transform]) =>
          record.compute(fieldName, transform),
        );
        Object.values(TRANSFORM_FIELDS).forEach((transform) =>
          transform(record),
        );

        const validated = await validateRecord(record);
        return validated;
      }),
    );
  });

  return (
    <Space config={SPACE_CONFIG}>
      <Sheet
        config={blueprint}
        onSubmit={async ({ data, sheet, job, event }) => {
          const allData: SheetData = await sheet.allData();
          const lots = allData.records.map(
            ({ values }) =>
              Object.fromEntries(
                Object.entries(values).map(([key, { value }]) => [
                  key,
                  value ?? null,
                ]),
              ) as unknown as z.infer<typeof LotSchema>,
          );

          console.log('Uploading lots', lots);

          await listingsUploadClient.upload(companyId, lots);
        }}
      />
    </Space>
  );
};

import { getUserSession } from '@reibus/frontend-utility';
import { LotSchemaWithBackendValidation } from '@reibus/ingest-listings-api-client-axios';

export const safeParseAsync = async (companyId: string, recordData) => {
  const userSession = await getUserSession();
  const listingsSchemaWithBackendValidation = LotSchemaWithBackendValidation({
    environment: process.env.ENVIRONMENT,
    token: userSession?.identityToken,
    companyId,
  });

  const result =
    await listingsSchemaWithBackendValidation.safeParseAsync(recordData);
  return result;
};

import { getUserSession } from '@reibus/frontend-utility';
import uploadClient from '@reibus/ingest-listings-api-client-axios';
import type { LotSchema } from '@reibus/ingest-listings-api-client-axios';
import type { z } from 'zod';

export const upload = async (
  companyId: string,
  lots: z.infer<typeof LotSchema>[],
) => {
  const userSession = await getUserSession();
  const listingsUploadClient = uploadClient({
    environment: process.env.ENVIRONMENT,
    token: userSession?.identityToken,
  });

  await listingsUploadClient.upload({
    parameters: {
      companyId,
    },
    body: {
      lots,
    },
  });
};

import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import type { Lot } from '@/types';

const useStyles = makeStyles()({
  tableRow: {
    height: 55,
    '&:nth-of-type(2n)': {
      backgroundColor: '#eee !important',
    },
  },
  tableCellText: {
    lineHeight: '1.3125rem',
  },
  link: {
    textAlign: 'center',
    lineHeight: '1.3125rem',
    backgroundColor: 'transparent',
    WebkitTextDecorationSkip: 'objects',
    color: '#2D6CCB',
    textDecoration: 'none',
  },
});

type Props = {
  id: string;
  name: string;
  lots: Lot[] | [];
  oldestListingAge: string;
  formattedUpdateDate: string;
};

export const Row = ({
  id,
  name,
  lots,
  oldestListingAge,
  formattedUpdateDate,
}: Props) => {
  const { classes } = useStyles();
  return (
    <TableRow
      className={classes.tableRow}
      key={`companyId-${id}`}
      id={`companyId-${id}`}
    >
      <TableCell align="left">
        <Link to={`company/${id}`} className={classes.link}>
          {name}
        </Link>
      </TableCell>
      <TableCell className={classes.tableCellText} align="right">
        {lots?.length}
      </TableCell>
      <TableCell className={classes.tableCellText} align="left">
        {oldestListingAge}
      </TableCell>
      <TableCell className={classes.tableCellText} align="left">
        {formattedUpdateDate === 'Invalid Date' ? '-' : formattedUpdateDate}
      </TableCell>
    </TableRow>
  );
};

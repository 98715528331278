import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { usePageView } from '@/hooks/usePageView';
import NotFoundImage from '@/images/404.png';

import Button from './atoms/Button';

const useStyles = makeStyles()({
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& h2': {
      margin: 0,
      color: '#64676B',
      fontFamily: 'Lato',
      fontSize: '64px',
      fontWeight: 600,
      lineHeight: '150%',
    },
    '& h1': {
      margin: 0,
      color: '#707070',
      fontFamily: 'Lato',
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    '& p': {
      margin: 0,
      color: '#707070',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '32px',
      fontWeight: 400,
      lineHeight: '150%',
    },
    '& button': {
      width: '287px',
      marginTop: '56px',
    },
  },
});

const PageNotFound = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  usePageView('404 Page not found');

  return (
    <div className={classes.notFound}>
      <img src={NotFoundImage} alt="404" />
      <h2>{t('dash404.weLostSomething')}</h2>
      <h1>{t('dash404.PageNotFound')}</h1>
      <p>{t('dash404.somethingWentWrong')}</p>
      <a href="/app/dashboard">
        <Button variant="primary" label={t('dash404.backToHome')} />
      </a>
    </div>
  );
};

export default PageNotFound;
